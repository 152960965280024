import React, { useState, useEffect, Suspense, lazy } from 'react';
import './App.css';
import './fonts.css'
import ReactGA from 'react-ga4';
import { verifyPassword } from './config';

// Only import Home component eagerly as it's needed for initial render
import Home from './Home';

// Lazy load all authenticated components
const NotesSecured = lazy(() => import('./NotesSecured'));
const Paelon = lazy(() => import('./Paelon'));
const Portfolio = lazy(() => import('./Portfolio'));
const EconomicCalendar = lazy(() => import('./EconomicCalender'));
const EarningsCalendar = lazy(() => import('./EarningsCalander'));
const Bookmark = lazy(() => import('./Bookmark'));

ReactGA.initialize('G-HVEPE59LKK');

function App() {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [password, setPassword] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [isListVisible, setIsListVisible] = useState(false);
    const [hoveredTab, setHoveredTab] = useState(null);
    const [activePage, setActivePage] = useState('Home');
    const [heightFlag, setHeightFlag] = useState(false);

    // Track initial pageview
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/home" });
    }, []);

    // Track page changes
    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: `/${activePage.toLowerCase()}`
        });
    }, [activePage]);

    // Preload authenticated components after successful login
    const preloadAuthComponents = () => {
        // Trigger lazy loading of all authenticated components
        const preloadPromises = [
            import('./NotesSecured'),
            import('./Paelon'),
            import('./Portfolio'),
            import('./EconomicCalender'),
            import('./EarningsCalander'),
            import('./Bookmark')
        ];
        
        Promise.all(preloadPromises).catch(console.error);
    };

    const togglePassword = () => {
        setIsPasswordVisible(!isPasswordVisible);
        ReactGA.event({
            category: "User Interaction",
            action: "Toggle Password Visibility",
            label: isPasswordVisible ? "Hide" : "Show"
        });
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = () => {
        if (verifyPassword(password)) {
            setIsAuthenticated(false);
            setIsPasswordVisible(false);
            setIsListVisible(true);
            // Preload components after successful authentication
            preloadAuthComponents();

            ReactGA.event({
                category: "User Authentication",
                action: "Login Success"
            });
        } else {
            alert('Incorrect password');
            ReactGA.event({
                category: "User Authentication",
                action: "Login Failed"
            });
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmit();
        }
    };

    const handleTabHover = (tabName) => {

    };

    const handleTabLeave = () => {
        setHoveredTab(null);
    };

    const handleTabClick = (pageName) => {
        setActivePage(pageName);
        setHeightFlag(pageName === 'Bookmark');

        ReactGA.event({
            category: "Navigation",
            action: "Page Change",
            label: pageName
        });
    };

    // Component to render the authenticated content with loading fallback
    const AuthenticatedContent = ({ component: Component }) => {
        if (!isListVisible) return null;
        
        return (
            <Suspense fallback={<div className="flex justify-center items-center">Loading...</div>}>
                <Component />
            </Suspense>
        );
    };

    return (
        <>
            <div className={`relative flex flex-col justify-center items-center ${heightFlag ? '' : 'h-screen'}`}>
                <div className="absolute top-0 left-0 m-4 flex items-center">
                    <div
                        className="cursor-pointer"
                        onClick={togglePassword}
                        style={{ visibility: isAuthenticated ? 'visible' : 'hidden' }}
                    >
                        <svg className="w-6 h-6 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 12a28.076 28.076 0 0 1-1.091 9M7.231 4.37a8.994 8.994 0 0 1 12.88 3.73M2.958 15S3 14.577 3 12a8.949 8.949 0 0 1 1.735-5.307m12.84 3.088A5.98 5.98 0 0 1 18 12a30 30 0 0 1-.464 6.232M6 12a6 6 0 0 1 9.352-4.974M4 21a5.964 5.964 0 0 1 1.01-3.328 5.15 5.15 0 0 0 .786-1.926m8.66 2.486a13.96 13.96 0 0 1-.962 2.683M7.5 19.336C9 17.092 9 14.845 9 12a3 3 0 1 1 6 0c0 .749 0 1.521-.031 2.311M12 12c0 3 0 6-2 9" />
                        </svg>
                    </div>
                    <div className={`ml-2 transition-opacity duration-700 ease-in-out ${isPasswordVisible ? 'opacity-100 visible' : 'opacity-0 invisible'}`}>
                        <form onSubmit={handleSubmit} className="flex items-center">
                            <input
                                type="password"
                                value={password}
                                onChange={handlePasswordChange}
                                onKeyDown={handleKeyDown}
                                placeholder="Password"
                                className="p-2 border rounded-l w-24"
                            />
                        </form>
                    </div>
                </div>

                {isListVisible && (
                    <div className="absolute left-0 top-0 p-4 coprgtb">
                        <ul className="list-none">
                            <li className='pb-2'>
                                <h2 className={`hover:scale-110 ${activePage === 'Home' ? '' : ''}`} onMouseEnter={() => handleTabHover('Home')} onMouseLeave={handleTabLeave} onClick={() => handleTabClick('Home')}>Home{hoveredTab === 'Home' ? ' >' : ''}</h2>
                            </li>
                            <li className='pb-2'>
                                <h2 className={`hover:scale-110 ${activePage === 'Paelon' ? '' : ''}`} onMouseEnter={() => handleTabHover('Paelon')} onMouseLeave={handleTabLeave} onClick={() => handleTabClick('Paelon')}>Paelon{hoveredTab === 'Paelon' ? ' >' : ''}</h2>
                            </li>
                            <li className='pb-2'>
                                <h2 className={`hover:scale-110 ${activePage === 'Portfolio' ? '' : ''}`} onMouseEnter={() => handleTabHover('Portfolio')} onMouseLeave={handleTabLeave} onClick={() => handleTabClick('Portfolio')}>Portfolio{hoveredTab === 'Portfolio' ? ' >' : ''}</h2>
                            </li>
                            <li className='pb-2'>
                                <h2 className={`hover:scale-110 ${activePage === 'NotesSecured' ? '' : ''}`} onMouseEnter={() => handleTabHover('NotesSecured')} onMouseLeave={handleTabLeave} onClick={() => handleTabClick('NotesSecured')}>NotesSecured{hoveredTab === 'NotesSecured' ? ' >' : ''}</h2>
                            </li>
                            <li className='pb-2'>
                                <h2 className={`hover:scale-110 ${activePage === 'EarningsCalender' ? '' : ''}`} onMouseEnter={() => handleTabHover('EarningsCalender')} onMouseLeave={handleTabLeave} onClick={() => handleTabClick('EarningsCalender')}>EarningsCalender{hoveredTab === 'EarningsCalender' ? ' >' : ''}</h2>
                            </li>
                            <li className='pb-2'>
                                <h2 className={`hover:scale-110 ${activePage === 'Bookmark' ? '' : ''}`} onMouseEnter={() => handleTabHover('Bookmark')} onMouseLeave={handleTabLeave} onClick={() => handleTabClick('Bookmark')}>Bookmarks{hoveredTab === 'Bookmark' ? ' >' : ''}</h2>
                            </li>
                        </ul>
                    </div>
                )}

                {/* Render the activePage with code splitting */}
                {activePage === 'Home' && <Home />}
                {activePage === 'Paelon' && <AuthenticatedContent component={Paelon} />}
                {activePage === 'Portfolio' && <AuthenticatedContent component={Portfolio} />}
                {activePage === 'NotesSecured' && <AuthenticatedContent component={NotesSecured} />}
                {activePage === 'EconomicCalender' && <AuthenticatedContent component={EconomicCalendar} />}
                {activePage === 'EarningsCalender' && <AuthenticatedContent component={EarningsCalendar} />}
                {activePage === 'Bookmark' && <AuthenticatedContent component={Bookmark} />}
            </div>
        </>
    );
}

export default App;